import * as bootstrap from 'bootstrap'
import '@fortawesome/fontawesome-free/css/all.css';

require('cookie-notice/dist/cookie.notice.min.js');

new cookieNoticeJS({
    // Shows the "learn more button (default=false)
    'learnMoreLinkEnabled': false,
    'expiresIn': 30,
    'fontFamily': 'inherit',
    'fontSize': '0.8rem',
    'buttonBgColor': '#5ab4b6',
    'buttonTextColor': '#000',
    'noticeBgColor': '#fff',
    'noticeTextColor': '#2f221b',
});